import React from "react";
import NavBar from "./NavBar";

import { IoBarChartSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import useAuthentication from "hooks/useAuthentication";
import { FaMoneyBillWave } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { MdHistory } from "react-icons/md";

const ReffererTopbar = () => {
  const { getInstance } = useAuthentication();
  const pathName = useLocation().pathname;

  React.useEffect(() => {
    getInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavBar.Container>
      <NavBar.Item
        to={"/refferer/dashboard"}
        icon={<IoBarChartSharp />}
        label="Dashboard"
        active={pathName.includes("/refferer/dashboard")}
      />
      <NavBar.Item
        to={"/refferer/withdrawals"}
        icon={<FaMoneyBillWave />}
        label="Withdrawals"
        active={pathName.includes("/refferer/withdrawals")}
      />
      <NavBar.Item
        to={"/refferer/earning-history"}
        icon={<MdHistory />}
        label="Earning History"
        active={pathName.includes("/earning-history")}
      />
      <NavBar.Item
        to={"/refferer/bank-details"}
        icon={<CgFileDocument />}
        label="Bank Details"
        active={pathName.includes("/refferer/bank-details")}
      />
    </NavBar.Container>
  );
};

export default ReffererTopbar;
