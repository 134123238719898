import { format, parse } from "date-fns";
import { useAdmin } from "hooks/useAdmin";
import React from "react";

const WithdrawalRequests = () => {
  const { getAllWithdrawalRecords, setWithdrawalStatus } = useAdmin();

  const [withdrawals, setWithdrawals] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [status, setStatus] = React.useState(0);

  const [bankName, setBankName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountHolder, setAccountHolder] = React.useState("");
  const [bankBranch, setBankBranch] = React.useState("");

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  const handleGetAllWithdrawalRecords = async () => {
    const {
      withdrawals,
      page: currentPage,
      totalPages,
    } = await getAllWithdrawalRecords(page, startDate, endDate, status);
    setWithdrawals(withdrawals);
    setPage(currentPage);
    setTotalPages(totalPages);
  };

  const handleStatusChange = (id, newStatus) => {
    setWithdrawalStatus(id, newStatus, handleGetAllWithdrawalRecords);
  };

  const handleSetBankDetails = (data) => {
    setBankName(data.bankName || "");
    setAccountNumber(data.accountNumber || "");
    setAccountHolder(data.accountHolder || "");
    setBankBranch(data.bankBranch || "");
  };

  React.useEffect(() => {
    handleGetAllWithdrawalRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, endDate, status]);

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                View Bank Details
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="bankName" className="form-label">
                    Bank Name
                  </label>
                  <input
                    onChange={(e) => setBankName(e.target.value)}
                    type="text"
                    className="form-control"
                    id="bankName"
                    placeholder="Enter your bank name"
                    value={bankName}
                    disabled
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="accountNumber" className="form-label">
                    Account Number
                  </label>
                  <input
                    onChange={(e) => setAccountNumber(e.target.value)}
                    type="text"
                    className="form-control"
                    id="accountNumber"
                    placeholder="Enter your account number"
                    value={accountNumber}
                    disabled
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="bankBranch" className="form-label">
                    Bank Branch
                  </label>
                  <input
                    onChange={(e) => setBankBranch(e.target.value)}
                    type="text"
                    className="form-control"
                    id="bankBranch"
                    placeholder="Enter the bank branch"
                    value={bankBranch}
                    disabled
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="accountHolder" className="form-label">
                    Account Holder Name
                  </label>
                  <input
                    onChange={(e) => setAccountHolder(e.target.value)}
                    type="text"
                    className="form-control"
                    id="accountHolder"
                    placeholder="Enter account holder's name"
                    value={accountHolder}
                    disabled
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center">
        <div className="card col-12 col-md-10 col-lg-10 mx-auto mt-4 table-responsive">
          <div className="row mb-4 px-3 py-2 align-items-center">
            <div className="col d-flex align-items-center gap-3">
              <div>
                <label htmlFor="startDate" className="form-label">
                  Start Date:
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="endDate" className="form-label">
                  End Date:
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-auto d-flex gap-3">
              <div className="d-flex align-items-center">
                <label className="form-label me-2 mb-0">Status:</label>
                <div className="form-check me-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="pendingStatus"
                    value="0"
                    checked={status === 0}
                    onChange={() => setStatus(0)}
                  />
                  <label className="form-check-label" htmlFor="pendingStatus">
                    Pending
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="approvedStatus"
                    value="1"
                    checked={status === 1}
                    onChange={() => setStatus(1)}
                  />
                  <label className="form-check-label" htmlFor="approvedStatus">
                    Approved
                  </label>
                </div>
              </div>
            </div>
          </div>
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">Amount</th>
                <th scope="col">Refferer</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.map((withdrawal) => (
                <tr key={withdrawal._id}>
                  <td>{withdrawal.amount}</td>
                  <td>{withdrawal.userEmail}</td>
                  <td>
                    {format(
                      parse(withdrawal.date, "yyyy-MM-dd HH:mm", new Date()),
                      "yyyy MMMM dd, hh:mm a"
                    )}
                  </td>
                  <td>
                    <select
                      className="form-select"
                      value={withdrawal.status}
                      onChange={(e) => handleStatusChange(withdrawal._id, parseInt(e.target.value))}
                    >
                      <option value={0}>Pending</option>
                      <option value={1}>Approved</option>
                    </select>
                  </td>
                  <td>
                    <button
                      onClick={() => handleSetBankDetails(withdrawal.bankDetails)}
                      type="button"
                      className="btn btn-success"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      View Bank Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="m-auto">
            <nav>
              <ul className="pagination justify-content-center">
                {startPage > 1 && (
                  <li onClick={() => setPage(startPage - 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <li
                    key={startPage + index}
                    onClick={() => setPage(startPage + index)}
                    className={`page-item ${startPage + index === page ? "active" : ""}`}
                  >
                    <a onClick={(e) => e.preventDefault()} className="page-link" href="/">
                      {startPage + index}
                    </a>
                  </li>
                ))}

                {endPage < totalPages && (
                  <li onClick={() => setPage(endPage + 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Next"
                    >
                      <span className="sr-only">Next</span>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawalRequests;
