import { useAdmin } from "hooks/useAdmin";
import React from "react";

const NoticeBoard = () => {
  const { getSiteConfiguration, setNoticeBoard } = useAdmin();

  const [clientNotice, setClientNotice] = React.useState("");

  const [referrerNotice, setReferrerNotice] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      const { clientNotice, referrerNotice } = await getSiteConfiguration();
      setClientNotice(clientNotice);
      setReferrerNotice(referrerNotice);
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    await setNoticeBoard(clientNotice, referrerNotice);
    window.location.reload();
  };

  return (
    <div className="container mt-3">
      <div className="card mx-auto" style={{ maxWidth: "600px" }}>
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="clientNotice" className="form-label">
              Client Notice
            </label>
            <textarea
              onChange={(e) => setClientNotice(e.target.value)}
              type="text"
              className="form-control"
              id="clientNotice"
              placeholder="Client Notice"
              value={clientNotice}
              rows={10}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="referrerNotice" className="form-label">
              Referrer Notice
            </label>
            <textarea
              onChange={(e) => setReferrerNotice(e.target.value)}
              type="text"
              className="form-control"
              id="referrerNotice"
              placeholder="Referrer Notice"
              value={referrerNotice}
              rows={10}
            />
          </div>

          <button onClick={handleSave} className="btn btn-success w-100">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoticeBoard;
