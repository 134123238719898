import { useAdmin } from "hooks/useAdmin";
import React from "react";
import { LiaDollarSignSolid, LiaUsersSolid } from "react-icons/lia";
import { IoMdSync } from "react-icons/io";
import { formatNumber } from "helpers/helperFunctions";

const AdminDashboard = () => {
  const { getDashboardData } = useAdmin();
  const [totalEarnings, setTotalEarnings] = React.useState(0);
  const [netProfit, setNetProfit] = React.useState(0);
  const [withdrawalsCount, setWithdrawalsCount] = React.useState(0);
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [personalUsers, setPersonalUsers] = React.useState(0);
  const [enterpriseUsers, setEnterpriseUsers] = React.useState(0);
  const [refferers, setRefferers] = React.useState(0);

  const handleDashboardData = async () => {
    const {
      totalEarnings,
      netProfit,
      withdrawalsCount,
      personalUsers,
      enterpriseUsers,
      refferers,
    } = await getDashboardData();

    setTotalEarnings(totalEarnings);
    setNetProfit(netProfit);
    setWithdrawalsCount(withdrawalsCount);
    setTotalUsers(personalUsers + enterpriseUsers + refferers);
    setPersonalUsers(personalUsers);
    setEnterpriseUsers(enterpriseUsers);
    setRefferers(refferers);
  };

  React.useEffect(() => {
    handleDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-primary">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Total Users</p>
                  <h4 className="my-1 text-primary">{formatNumber(totalUsers)}</h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-primary"
                  style={styles.iconWrapper}
                >
                  <LiaUsersSolid size={30} color="#fff" />
                </div>
              </div>

              <div className="mt-4">
                <p className="link-primary">Personal users: {personalUsers}</p>
                <p className="link-primary">Enterprise users: {enterpriseUsers}</p>
                <p className="link-primary">Refferers: {refferers}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-success">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Total Earnings</p>
                  <h4 className="my-1 text-success">
                    {totalEarnings.toFixed(2).toLocaleString()} LKR
                  </h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-success"
                  style={styles.iconWrapper}
                >
                  <LiaDollarSignSolid size={30} color="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-danger">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Net Profit</p>
                  <h4 className="my-1 text-danger">{netProfit.toFixed(2).toLocaleString()} LKR</h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-danger"
                  style={styles.iconWrapper}
                >
                  <LiaDollarSignSolid size={30} color="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-warning">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Withdrawal Requests</p>
                  <h4 className="my-1 text-warning">{formatNumber(withdrawalsCount)}</h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-warning"
                  style={styles.iconWrapper}
                >
                  <IoMdSync size={30} color="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;

const styles = {
  iconWrapper: {
    width: 60,
    height: 60,
  },
};
