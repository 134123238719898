import React from "react";
import { format, parse } from "date-fns";
import { useAdmin } from "hooks/useAdmin";
import { useDebounce } from "use-debounce";
import { MdDelete, MdEdit, MdRemoveRedEye, MdSync } from "react-icons/md";

const Users = () => {
  const { getUsers, getUser, createUser, updateUser, deleteUser, resetClient } = useAdmin();

  const [users, setUsers] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [query, setQuery] = React.useState("");

  const [debouncedQuery] = useDebounce(query, 500);

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  // states for modal
  const [actionType, setActionType] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [credits, setCredits] = React.useState(0);

  const [accountType, setAccountType] = React.useState("");

  const handleGetUsers = async (debouncedQuery, accountType) => {
    const {
      users,
      page: currentPage,
      totalPages,
    } = await getUsers(page, debouncedQuery ? debouncedQuery : query, accountType);
    setUsers(users);
    setPage(currentPage);
    setTotalPages(totalPages);
  };

  const handleGetUser = async (id) => {
    const { username, email, contactNumber, credits } = await getUser(id);
    setUserId(id);
    setUsername(username);
    setEmail(email);
    setContactNumber(contactNumber);
    setCredits(credits);
    setTotalPages(totalPages);
  };

  React.useEffect(() => {
    handleGetUsers(debouncedQuery !== "" ? debouncedQuery : undefined, accountType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedQuery, accountType]);

  const clearFields = () => {
    setUserId("");
    setUsername("");
    setEmail("");
    setContactNumber("");
    setPassword("");
    setCredits(0);
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {actionType === "view"
                  ? "View User"
                  : actionType === "update"
                  ? "Edit User"
                  : "Create User"}
              </h1>
              <button
                onClick={clearFields}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                    disabled={actionType === "view"}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                    disabled={actionType === "view"}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <input
                    className="form-control"
                    id="contactNumber"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    placeholder="Contact Number (07xxxxxxxxx)"
                    required
                    disabled={actionType === "view"}
                  />
                </div>
                {actionType !== "view" && (
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                      disabled={actionType === "view"}
                      type="password"
                    />
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="credits" className="form-label">
                    Credits
                  </label>
                  <input
                    className="form-control"
                    id="credits"
                    value={credits}
                    onChange={(e) => setCredits(e.target.value)}
                    placeholder="Credits"
                    required
                    disabled={actionType === "view"}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={clearFields}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {actionType !== "view" && (
                <button
                  onClick={
                    actionType === "create"
                      ? () => createUser(username, email, contactNumber, password, credits)
                      : () => updateUser(userId, username, email, contactNumber, password, credits)
                  }
                  type="button"
                  className="btn btn-success"
                >
                  {actionType === "update" ? "Update user" : "Create user"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center">
        <div className="card col-12 col-md-12 col-lg-12 mx-auto mt-4 table-responsive">
          <div className="row mb-4 px-3 py-2 align-items-center">
            <div className="col d-flex align-items-center gap-3">
              <div>
                <label htmlFor="searchUser" className="form-label">
                  Search User:
                </label>
                <input
                  id="searchUser"
                  className="form-control"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="searchUser" className="form-label">
                  User Type:
                </label>
                <select onChange={(e) => setAccountType(e.target.value)} className="form-select">
                  <option value="">All</option>
                  <option value="personal">Personal</option>
                  <option value="enterprise">Enterprise</option>
                </select>
              </div>
            </div>
            <div className="col-auto">
              <button
                onClick={() => {
                  setActionType("create");
                  clearFields();
                }}
                type="button"
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                Create User
              </button>
            </div>
          </div>
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">Username</th>
                <th scope="col">Email</th>
                <th scope="col">Type</th>
                <th scope="col">Credits</th>
                <th scope="col">Joined at</th>
                <th scope="col">Referrer</th>
                <th scope="col">WhatsApp</th>
                <th scope="col">Contact</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.accountType.charAt(0).toUpperCase() +
                      user.accountType.slice(1).toLowerCase()}
                  </td>
                  <td>{user.credits}</td>
                  <td>
                    {format(
                      parse(user.joinedAt, "yyyy-MM-dd HH:mm", new Date()),
                      "yyyy MMMM dd, hh:mm a"
                    )}
                  </td>
                  <td>{user.refferrerOf ? user.refferrerOf.email : "None"}</td>
                  <td>
                    {user.client.mobileNumber ? user.client.mobileNumber.split("@")[0] : "None"}
                  </td>
                  <td>{user.contactNumber ? user.contactNumber : "None"}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={() => {
                          setActionType("view");
                          handleGetUser(user._id);
                        }}
                        className="rounded-circle bg-dark border-0 p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <MdRemoveRedEye color="white" />
                      </button>
                      &nbsp;
                      <button
                        onClick={() => resetClient(user._id)}
                        className="rounded-circle bg-primary border-0 p-2"
                      >
                        <MdSync color="white" />
                      </button>
                      &nbsp;
                      <button
                        onClick={() => {
                          setActionType("update");
                          handleGetUser(user._id);
                        }}
                        className="rounded-circle bg-warning border-0 p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <MdEdit />
                      </button>
                      &nbsp;
                      <button
                        onClick={() => deleteUser(user._id)}
                        className="rounded-circle bg-danger border-0 p-2"
                      >
                        <MdDelete color="white" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="m-auto">
            <nav>
              <ul className="pagination justify-content-center">
                {startPage > 1 && (
                  <li onClick={() => setPage(startPage - 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <li
                    key={startPage + index}
                    onClick={() => setPage(startPage + index)}
                    className={`page-item ${startPage + index === page ? "active" : ""}`}
                  >
                    <a onClick={(e) => e.preventDefault()} className="page-link" href="/">
                      {startPage + index}
                    </a>
                  </li>
                ))}

                {endPage < totalPages && (
                  <li onClick={() => setPage(endPage + 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Next"
                    >
                      <span className="sr-only">Next</span>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
