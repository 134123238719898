import React from "react";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/client/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default Main;
