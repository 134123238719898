import appStore from "store/appStore";
import useAlertEmiter from "./useAlertEmmiter";
import { useHttp } from "./useHttp";

export const useAdmin = () => {
  const { protectedHttp } = useHttp();
  const { emitError, emitSuccess } = useAlertEmiter();
  const { setLoading } = appStore();

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get("/admin/dashboard");

      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getPayments = async (page, startDate, endDate, email) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(
        `/admin/payments?page=${page}&startDate=${startDate}&endDate=${endDate}&email=${email}`
      );
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getUsers = async (page, query, accountType) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(
        `/admin/users?page=${page}&query=${query}&accountType=${accountType}`
      );
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getReferrers = async (page, query) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/admin/referrers?page=${page}&query=${query}`);
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async (id) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/admin/users/${id}`);
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (username, email, contactNumber, password, credits) => {
    if (!username || !email || !contactNumber || !password) {
      return alert("All fields are required");
    }

    try {
      setLoading(true);
      await protectedHttp.post("/admin/users", {
        username,
        email,
        contactNumber,
        password,
        credits,
        role: 1,
      });

      window.location.reload();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const resetClient = async (id) => {
    if (!window.confirm("Are you sure you want to reset this client?")) {
      return;
    } else {
      try {
        setLoading(true);
        await protectedHttp.delete(`/admin/reset-client/${id}`);

        window.location.reload();
      } catch (error) {
        emitError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const updateUser = async (id, username, email, contactNumber, password, credits) => {
    if (!username || !email || !contactNumber) {
      return alert("All fields are required");
    }

    try {
      setLoading(true);
      await protectedHttp.put(`/admin/users/${id}`, {
        username,
        email,
        contactNumber,
        password,
        credits,
      });

      window.location.reload();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    } else {
      try {
        setLoading(true);
        await protectedHttp.delete(`/admin/users/${id}`);

        window.location.reload();
      } catch (error) {
        emitError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const createReferrer = async (username, email, password, contactNumber) => {
    if (!username || !email || !password || !contactNumber) {
      return alert("All fields are required");
    }

    try {
      setLoading(true);
      await protectedHttp.post("/admin/users", {
        username,
        email,
        password,
        role: 8,
        contactNumber,
      });

      window.location.reload();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateReferrer = async (id, username, email, password, contactNumber) => {
    if (!username || !email || !contactNumber) {
      return alert("All fields are required");
    }

    try {
      setLoading(true);
      await protectedHttp.put(`/admin/users/${id}`, {
        username,
        email,
        password,
        contactNumber,
      });

      window.location.reload();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllWithdrawalRecords = async (page, startDate, endDate, status) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(
        `/admin/withdrawals?page=${page}&startDate=${startDate}&endDate=${endDate}&status=${status}`
      );
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const setWithdrawalStatus = async (id, status, cb) => {
    try {
      setLoading(true);
      await protectedHttp.put(`/admin/withdrawals/status/${id}`, { status });
      emitSuccess("Withdrawal status updated successfully");
      cb();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getSiteConfiguration = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/utils/configuration`);
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const setNoticeBoard = async (clientNotice, referrerNotice) => {
    try {
      setLoading(true);
      await protectedHttp.post("/admin/configuration", {
        clientNotice,
        referrerNotice,
      });
      emitSuccess("Notice board updated successfully");
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getDashboardData,
    getPayments,
    getAllWithdrawalRecords,
    setWithdrawalStatus,
    getUsers,
    getReferrers,
    getUser,
    createUser,
    createReferrer,
    updateUser,
    updateReferrer,
    deleteUser,
    getSiteConfiguration,
    setNoticeBoard,
    resetClient,
  };
};
