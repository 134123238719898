import Spacer from "components/Spacer";
import { checkUserIsSignedIn } from "helpers/helperFunctions";
import useAuthentication from "hooks/useAuthentication";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { loginUser } = useAuthentication();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleUserPage = () => {
    queryParams.get("email") && setEmail(queryParams.get("email"));
    const isSignedIn = checkUserIsSignedIn();

    if (isSignedIn) {
      navigate("/");
    }
  };

  React.useEffect(() => {
    handleUserPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(email, password);
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card col-sm col-md-6 col-lg-4  mx-auto">
        <div className="card-body d-flex flex-column justify-center align-center">
          <img src={require("assets/images/hero-trans.png")} style={styles.logo} alt="logo" />
          <br />
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="inputEmail" className="form-label">
                Email address
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control"
                id="inputEmail"
                value={email}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">
                Password
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                className="form-control"
                id="inputPassword"
                value={password}
              />
            </div>

            <button type="submit" className="btn btn-success w-100" disabled={!email || !password}>
              Login
            </button>

            <Spacer height={20} />
            <div style={styles.bottomContainer}>
              <a href="/" className="link-success">
                Forgot Password?
              </a>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/register");
                }}
                href="/"
                className="link-success"
              >
                Register an account
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

const styles = {
  logo: {
    width: 250,
    height: 100,
    alignSelf: "center",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
  },
};
