import { Slide, toast } from "react-toastify";

const useAlertEmiter = () => {
  const emitError = (error) => {
    const errorMessage = error.response?.data?.msg;
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: false,
      theme: "light",
      transition: Slide,
      containerId: "error-toast",
    });
  };

  const emitSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: false,
      theme: "light",
      transition: Slide,
      containerId: "success-toast",
    });
  };

  return {
    emitError,
    emitSuccess,
  };
};

export default useAlertEmiter;
