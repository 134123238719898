import { formatNumber } from "helpers/helperFunctions";
import useAlertEmiter from "hooks/useAlertEmmiter";
import { useRefferer } from "hooks/useRefferer";
import React from "react";
import { FaExclamationTriangle, FaMoneyBillWave } from "react-icons/fa";
import { LiaDollarSignSolid, LiaUsersSolid } from "react-icons/lia";

const ReffererDashboard = () => {
  const { getDashboardData, getSiteConfiguration } = useRefferer();
  const { emitSuccess } = useAlertEmiter();
  const [referrals, setReferrals] = React.useState(0);
  const [referralLink, setReferralLink] = React.useState("");
  const [totalEarnings, setTotalEarnings] = React.useState(0);
  const [availableWithdrawalAmount, setAvailableWithdrawalAmount] = React.useState(0);

  const [notice, setNotice] = React.useState("");

  const handleDashboardData = async () => {
    const { totalRefferals, totalEarnings, availableAmountForWithdraw, referralLink } =
      await getDashboardData();

    setReferrals(totalRefferals);
    setTotalEarnings(totalEarnings);
    setReferralLink(referralLink);
    setAvailableWithdrawalAmount(availableAmountForWithdraw);
  };

  React.useEffect(() => {
    handleDashboardData();

    getSiteConfiguration().then((config) => setNotice(config.referrerNotice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyReferralLink = () => {
    navigator.clipboard.writeText(referralLink);
    emitSuccess("Referral link copied to clipboard!");
  };

  return (
    <>
      {notice && (
        <div className="container d-flex align-items-center">
          <div className="col col-md col-lg-8 mx-auto mt-4">
            <div className="alert alert-warning d-flex align-items-center" role="alert">
              <FaExclamationTriangle className="me-2" size={20} />
              <div>{notice}</div>
            </div>
          </div>
        </div>
      )}

      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-primary">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Total Referrals</p>
                  <h4 className="my-1 text-primary">{formatNumber(referrals)}</h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-primary"
                  style={styles.iconWrapper}
                >
                  <LiaUsersSolid size={30} color="#fff" />
                </div>
              </div>

              <div className="d-flex gap-4 mt-4">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleCopyReferralLink();
                  }}
                  href="/"
                  className="link-primary"
                >
                  Click here to copy your referral link
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-success">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Total Earnings</p>
                  <h4 className="my-1 text-success">
                    {totalEarnings.toFixed(2).toLocaleString()} LKR
                  </h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-success"
                  style={styles.iconWrapper}
                >
                  <LiaDollarSignSolid size={30} color="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-warning">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Available Amount for Withdraw</p>
                  <h4 className="my-1 text-warning">
                    {availableWithdrawalAmount.toFixed(2).toLocaleString()} LKR
                  </h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-warning"
                  style={styles.iconWrapper}
                >
                  <FaMoneyBillWave size={30} color="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReffererDashboard;

const styles = {
  iconWrapper: {
    width: 60,
    height: 60,
  },
};
