import React from "react";
import useAuthentication from "hooks/useAuthentication";
import { useNavigate } from "react-router-dom";

import { IoPowerSharp } from "react-icons/io5";

const Container = ({ children }) => {
  const { signOut } = useAuthentication();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-success">
      <div className="container-fluid">
        <span className="navbar-brand">WhatsFlow</span>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">{children}</ul>
          <div className="navbar-text text-white">
            <button
              onClick={signOut}
              className="nav-link"
              aria-current="page"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoPowerSharp />
              &nbsp;Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

const Item = ({ to, label, icon, active }) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(to)}
      className={`nav-link ${active ? "active" : ""}`}
      aria-current="page"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon}
      &nbsp;{label}
    </button>
  );
};

const NavBar = {
  Container,
  Item,
};

export default NavBar;
