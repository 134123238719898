import { create } from "zustand";

const appStore = create()((set) => ({
  loading: false,
  errorMessage: undefined,
  setLoading: (loading) => {
    set({ loading });
  },
  setErrorMessage: (errorMessage) => {
    set({ errorMessage });
  },
}));

export default appStore;
