import React from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";

import useClient from "hooks/useClient";

const ViewTemplate = () => {
  const { templateId } = useParams();

  const navigate = useNavigate();
  const { getTemplate, deleteTemplate } = useClient();

  const [templateCode, setTemplateCode] = React.useState("");
  const [templateName, setTemplateName] = React.useState("");
  const [text, setText] = React.useState("");

  const textareaRef = React.useRef(null);

  const handleGetTemplate = async () => {
    try {
      const template = await getTemplate(templateId);

      setTemplateName(template.name);
      setText(template.message);
      setTemplateCode(template.templateCode);

      renderPreview(template.message);
    } catch (error) {
      redirect("/templates");
    }
  };

  const renderPreview = (text) => {
    let previewText = text
      .replace(/\*(.*?)\*/g, "<b>$1</b>")
      .replace(/_(.*?)_/g, "<i>$1</i>")
      .replace(/~(.*?)~/g, "<s>$1</s>")
      .replace(/`(.*?)`/g, "<code>$1</code>")
      .replace(/\n/g, "<br/>");

    return { __html: previewText };
  };

  React.useEffect(() => {
    handleGetTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-4">
      <div className="text-center mb-5">
        <h1>View Template</h1>
        <h5 className="text-muted">{templateName}</h5>
        <h5 className="text-muted">Code: {templateCode}</h5>
      </div>
      <div className="row mb-4">
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex flex-column">
          <div className="mb-2">
            <button className="btn btn-outline-secondary me-2" type="button" disabled>
              Insert Variable
            </button>
            <button className="btn btn-outline-secondary me-2" type="button" disabled>
              Bold
            </button>
            <button className="btn btn-outline-secondary me-2" type="button" disabled>
              Italic
            </button>
            <button className="btn btn-outline-secondary me-2" type="button" disabled>
              Strikethrough
            </button>
            <button className="btn btn-outline-secondary" type="button" disabled>
              Monospace
            </button>
          </div>
          <div className="flex-grow-1">
            <textarea
              className="form-control"
              ref={textareaRef}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Type something..."
              rows="10"
              disabled
            ></textarea>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column">
          <h5>Preview:</h5>
          <div
            className="preview p-2 border flex-grow-1"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={renderPreview(text)}
          ></div>
        </div>
      </div>
      <div className="text-center mt-4">
        <button
          onClick={() => deleteTemplate(templateId, () => navigate("/templates"))}
          className="btn btn-danger"
        >
          Delete Template
        </button>
      </div>
    </div>
  );
};

export default ViewTemplate;
