import Cookies from "js-cookie";

export const checkUserIsSignedIn = () => {
  const userToken = Cookies.get("u_token");
  return userToken ? true : false;
};

export const formatNumber = (num) => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1) + "b";
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "m";
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "k";
  }
  return num.toString();
};
