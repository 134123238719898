import React from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";

import useClient from "hooks/useClient";

const UpdateTemplate = () => {
  const { templateId } = useParams();

  const navigate = useNavigate();
  const { getTemplate, updateTemplate } = useClient();

  const [type, setType] = React.useState("");
  const [templateName, setTemplateName] = React.useState("");
  const [text, setText] = React.useState("");

  const textareaRef = React.useRef(null);

  const handleGetTemplate = async () => {
    try {
      const template = await getTemplate(templateId);

      setType(template.type);
      setTemplateName(template.name);
      setText(template.message);

      renderPreview(template.message);
    } catch (error) {
      redirect("/client/templates");
    }
  };

  React.useEffect(() => {
    handleGetTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatSelectedText = (formatType) => {
    const textarea = textareaRef.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const selectedText = text.substring(startPos, endPos);

    if (!selectedText) return;

    let formattedText = "";
    switch (formatType) {
      case "bold":
        if (selectedText.startsWith("*") && selectedText.endsWith("*")) {
          formattedText = selectedText.slice(2, -2);
        } else {
          formattedText = `*${selectedText}*`;
        }
        break;
      case "italic":
        if (selectedText.startsWith("_") && selectedText.endsWith("_")) {
          formattedText = selectedText.slice(1, -1);
        } else {
          formattedText = `_${selectedText}_`;
        }
        break;
      case "strikethrough":
        if (selectedText.startsWith("~") && selectedText.endsWith("~")) {
          formattedText = selectedText.slice(1, -1);
        } else {
          formattedText = `~${selectedText}~`;
        }
        break;
      case "monospace":
        if (selectedText.startsWith("`") && selectedText.endsWith("`")) {
          formattedText = selectedText.slice(1, -1);
        } else {
          formattedText = `\`${selectedText}\``;
        }
        break;
      default:
        break;
    }

    const newText = text.substring(0, startPos) + formattedText + text.substring(endPos);

    setText(newText);

    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = startPos + formattedText.length;
      textarea.focus();
    }, 0);
  };

  const insertVariable = () => {
    const textarea = textareaRef.current;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const newText = text.substring(0, startPos) + " {{variable}}" + text.substring(endPos);
    setText(newText);

    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = startPos + 13;
      textarea.focus();
    }, 0);
  };

  const renderPreview = (text) => {
    let previewText = text
      .replace(/\*(.*?)\*/g, "<b>$1</b>")
      .replace(/_(.*?)_/g, "<i>$1</i>")
      .replace(/~(.*?)~/g, "<s>$1</s>")
      .replace(/`(.*?)`/g, "<code>$1</code>")
      .replace(/\n/g, "<br/>");

    return { __html: previewText };
  };

  const handleUpdateTemplate = () => {
    if (!templateName) {
      alert("Please enter a template name");
      return;
    }

    if (!text) {
      alert("Please enter a message");
      return;
    }

    updateTemplate(templateId, { type, name: templateName, message: text }, () => {
      navigate("/client/templates");
    });
  };

  return (
    <div className="container mt-4">
      <div className="text-center mb-5">
        <h1>Update Template</h1>
        <h5 className="text-muted">{templateName}</h5>
        <h5 className="text-muted">{type} template</h5>
      </div>
      <div className="row mb-4">
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 d-flex flex-column">
          <div className="mb-2">
            <button
              className="btn btn-outline-secondary me-2"
              type="button"
              onClick={insertVariable}
            >
              Insert Variable
            </button>
            <button
              className="btn btn-outline-secondary me-2"
              type="button"
              onClick={() => formatSelectedText("bold")}
            >
              Bold
            </button>
            <button
              className="btn btn-outline-secondary me-2"
              type="button"
              onClick={() => formatSelectedText("italic")}
            >
              Italic
            </button>
            <button
              className="btn btn-outline-secondary me-2"
              type="button"
              onClick={() => formatSelectedText("strikethrough")}
            >
              Strikethrough
            </button>
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => formatSelectedText("monospace")}
            >
              Monospace
            </button>
          </div>
          <div className="flex-grow-1">
            <textarea
              className="form-control"
              ref={textareaRef}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Type something..."
              rows="10"
            ></textarea>
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column">
          <h5>Preview:</h5>
          <div
            className="preview p-2 border flex-grow-1"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={renderPreview(text)}
          ></div>
        </div>
      </div>
      <div className="text-center mt-4">
        <button onClick={handleUpdateTemplate} className="btn btn-primary">
          Update Template
        </button>
      </div>
    </div>
  );
};

export default UpdateTemplate;
