import React from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Login, Panel, Register, AdminPanel, ReffererPanel } from "pages";
import Loader from "components/Loader";
import appStore from "store/appStore";
import Main from "pages/Main";

const HandleErrorPage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/client/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const App = () => {
  const { loading } = appStore();

  return (
    <>
      {loading && <Loader />}
      <ToastContainer containerId="error-toast" />
      <ToastContainer containerId="success-toast" />
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/client/*" element={<Panel />} />
          <Route path="/admin/*" element={<AdminPanel />} />
          <Route path="/refferer/*" element={<ReffererPanel />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<HandleErrorPage />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
