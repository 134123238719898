import React from "react";
import NavBar from "./NavBar";
import { IoBarChartSharp, IoAlbumsSharp } from "react-icons/io5";
import userStore from "store/userStore";
import useClient from "hooks/useClient";
import { useLocation } from "react-router-dom";
import useAuthentication from "hooks/useAuthentication";
import { MdHistory } from "react-icons/md";

const ClientTopbar = () => {
  const { whatsappLoggedIn } = userStore();
  const { watchClient } = useClient();
  const { getInstance } = useAuthentication();
  const pathName = useLocation().pathname;

  React.useEffect(() => {
    getInstance();
    watchClient();

    const intervalId = setInterval(() => {
      watchClient();
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavBar.Container>
      {whatsappLoggedIn && (
        <>
          <NavBar.Item
            to={"/client/dashboard"}
            icon={<IoBarChartSharp />}
            label="Dashboard"
            active={pathName.includes("/dashboard")}
          />
          <NavBar.Item
            to={"/client/templates"}
            icon={<IoAlbumsSharp />}
            label="Templates"
            active={pathName.includes("/templates")}
          />
          <NavBar.Item
            to={"/client/topup-history"}
            icon={<MdHistory />}
            label="Topup History"
            active={pathName.includes("/topup-history")}
          />
        </>
      )}
    </NavBar.Container>
  );
};

export default ClientTopbar;
