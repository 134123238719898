import React from "react";
import { Routes, Route } from "react-router-dom";

import ReffererDashboard from "./ReffererDashboard";
import ReffererTopbar from "components/ReffererTopbar";
import Withdrawals from "./Withdrawals";
import BankDetails from "./BankDetails";
import EarningHistory from "./EarningHistory";

const ReffererPanel = () => {
  return (
    <div>
      <ReffererTopbar />
      <Routes>
        <Route path="/dashboard" element={<ReffererDashboard />} />
        <Route path="/withdrawals" element={<Withdrawals />} />
        <Route path="/bank-details" element={<BankDetails />} />
        <Route path="/earning-history" element={<EarningHistory />} />
      </Routes>
    </div>
  );
};

export default ReffererPanel;
