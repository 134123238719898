import { useNavigate } from "react-router-dom";

import useAlertEmmiter from "./useAlertEmmiter";
import userStore from "store/userStore";
import appStore from "store/appStore";
import { useHttp } from "./useHttp";

const useClient = () => {
  const { protectedHttp } = useHttp();
  const { setLoading } = appStore();
  const navigate = useNavigate();

  const { setWhatsappLoggedIn, setWhatsappNumber, setClientId } = userStore();
  const { emitError, emitSuccess } = useAlertEmmiter();

  const watchClient = async () => {
    try {
      const { data } = await protectedHttp.get("/client/get-client");

      const clientInfo = data.client;

      setWhatsappLoggedIn(clientInfo.whatsappLoggedIn);
      setWhatsappNumber(clientInfo.whatsappNumber);
      setClientId(clientInfo.clientId);

      if (!clientInfo.whatsappLoggedIn) {
        navigate("/client/scan-qr");
      }
    } catch (error) {
      emitError(error);
    }
  };

  const getRemainingCredits = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get("/client/get-remaining-credits");
      return data.credits;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getTemplates = async (page) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/client/templates?page=${page}`);
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const createTemplate = async (template, cb) => {
    try {
      setLoading(true);
      await protectedHttp.post("/client/template", template);
      emitSuccess("Template created successfully");
      cb();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getTemplate = async (id) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/client/template/${id}`);
      return data.template;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateTemplate = async (id, template, cb) => {
    try {
      setLoading(true);
      await protectedHttp.put(`/client/template/${id}`, template);
      emitSuccess("Template updated successfully");
      cb();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteTemplate = async (id, cb) => {
    if (!window.confirm("Are you sure you want to delete this template?")) {
      return;
    } else {
      try {
        setLoading(true);
        await protectedHttp.delete(`/client/template/${id}`);
        emitSuccess("Template deleted successfully");
        cb();
      } catch (error) {
        emitError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getSiteConfiguration = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/utils/configuration`);
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const downgradeToPersonal = async () => {
    if (
      !window.confirm(
        "This action can't be reverted and you will not be refunded!, Are you sure you want to downgrade to personal account?"
      )
    ) {
      return;
    } else {
      try {
        setLoading(true);
        await protectedHttp.post(`/client/downgrade`);
        window.location.reload();
      } catch (error) {
        emitError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getEnterprisePaymentRecord = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/client/enterprise-payment`);
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const downgradeToPersonalAutomatically = async () => {
    try {
      setLoading(true);
      await protectedHttp.post(`/client/downgrade`);
      window.location.reload();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getTopupHistory = async (page, startDate, endDate) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(
        `/client/topup-history?page=${page}&startDate=${startDate}&endDate=${endDate}`
      );
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    watchClient,
    getRemainingCredits,
    getTemplates,
    deleteTemplate,
    createTemplate,
    updateTemplate,
    getTemplate,
    getSiteConfiguration,
    downgradeToPersonal,
    getEnterprisePaymentRecord,
    downgradeToPersonalAutomatically,
    getTopupHistory,
  };
};

export default useClient;
