import React from "react";
import { format, parse } from "date-fns";
import useClient from "hooks/useClient";

const TopupHistory = () => {
  const { getTopupHistory } = useClient();

  const [topupHistory, setTopupHistory] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  const handleTopupHistory = async () => {
    const {
      topupHistory,
      page: currentPage,
      totalPages,
    } = await getTopupHistory(page, startDate, endDate);
    setTopupHistory(topupHistory);
    setPage(currentPage);
    setTotalPages(totalPages);
  };

  React.useEffect(() => {
    handleTopupHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, endDate]);

  return (
    <div className="container d-flex flex-column align-items-center">
      <div className="card col-12 col-md-10 col-lg-10 mx-auto mt-4 table-responsive">
        <div className="row mb-4 px-3 py-2 align-items-center">
          <div className="col d-flex align-items-center gap-3">
            <div>
              <label htmlFor="startDate" className="form-label">
                Start Date:
              </label>
              <input
                type="date"
                id="startDate"
                className="form-control"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="endDate" className="form-label">
                End Date:
              </label>
              <input
                type="date"
                id="endDate"
                className="form-control"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="col-auto d-flex flex-column gap-3 align-items-end">
            <span>Total Earnings: {totalAmount.toLocaleString()} LKR</span>
            <span>Net Earnings: {totalNetProfit.toLocaleString()} LKR</span>
          </div> */}
        </div>
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col">Credits</th>
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
              <th scope="col">Plan</th>
            </tr>
          </thead>
          <tbody>
            {topupHistory.map((topup) => (
              <tr key={topup._id}>
                <td>{topup.creditsAmount}</td>
                <td>{topup.paymentAmount.toLocaleString()} LKR</td>

                <td>
                  {format(
                    parse(topup.createdAt, "yyyy-MM-dd HH:mm:ss", new Date()),
                    "yyyy MMMM dd, hh:mm:ss a"
                  )}
                </td>
                <td>{topup.type.charAt(0).toUpperCase() + topup.type.slice(1).toLowerCase()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="m-auto">
          <nav>
            <ul className="pagination justify-content-center">
              {startPage > 1 && (
                <li onClick={() => setPage(startPage - 1)} className="page-item">
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="page-link"
                    href="/"
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
              )}

              {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                <li
                  key={startPage + index}
                  onClick={() => setPage(startPage + index)}
                  className={`page-item ${startPage + index === page ? "active" : ""}`}
                >
                  <a onClick={(e) => e.preventDefault()} className="page-link" href="/">
                    {startPage + index}
                  </a>
                </li>
              ))}

              {endPage < totalPages && (
                <li onClick={() => setPage(endPage + 1)} className="page-item">
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="page-link"
                    href="/"
                    aria-label="Next"
                  >
                    <span className="sr-only">Next</span>
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TopupHistory;
