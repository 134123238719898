import axios from "axios";
import Cookies from "js-cookie";

export const useHttp = () => {
  const BASE_URL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://127.0.0.1:3001" : "";

  const http = axios.create({
    baseURL: `${BASE_URL}/api`,
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
    },
  });

  const protectedHttp = axios.create({
    baseURL: `${BASE_URL}/api`,
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
    },
  });

  const protectedClientIdHttp = axios.create({
    baseURL: `${BASE_URL}/api`,
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
    },
  });

  const getBrowserName = () => {
    const userAgent = navigator.userAgent;
    let browserName = "Unknown";

    if (userAgent.includes("Firefox")) {
      browserName = "Firefox";
    } else if (userAgent.includes("Chrome")) {
      browserName = "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      browserName = "Safari";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
      browserName = "Internet Explorer";
    } else if (userAgent.includes("Edge")) {
      browserName = "Edge";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      browserName = "Opera";
    }

    return browserName;
  };

  const addDeviceHeader = (config) => {
    const device = getBrowserName();
    config.headers["Device"] = device;
    return config;
  };

  http.interceptors.request.use(addDeviceHeader);

  protectedHttp.interceptors.request.use((config) => {
    const token = Cookies.get("u_token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return addDeviceHeader(config);
  });

  protectedHttp.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401) {
        Cookies.remove("u_token");
        window.location.reload();
      }

      if ((error.response && error.response.status === 409) || error.response.status === 500) {
        console.error("Resource not found (404). Attempting to initialize the client.");

        const initResponse = await protectedHttp.post("/client/initialize-client");

        if (initResponse.status === 200) {
          console.log("Client initialization successful. Retrying the original request.");
          return protectedClientIdHttp(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );

  return { http, protectedHttp, BASE_URL };
};
