import React from "react";
import { Routes, Route } from "react-router-dom";

import AdminTopbar from "components/AdminTopbar";
import AdminDashboard from "./AdminDashboard";
import Payments from "./Payments";
import Users from "./Users";
import WithdrawalRequests from "./WithdrawalRequests";
import Referrers from "./Referrers";
import NoticeBoard from "./NoticeBoard";

const AdminPanel = () => {
  return (
    <div>
      <AdminTopbar />
      <Routes>
        <Route path="/dashboard" element={<AdminDashboard />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/withdrawals" element={<WithdrawalRequests />} />
        <Route path="/users" element={<Users />} />
        <Route path="/referrers" element={<Referrers />} />
        <Route path="/notice-board" element={<NoticeBoard />} />
      </Routes>
    </div>
  );
};

export default AdminPanel;
