import { useRefferer } from "hooks/useRefferer";
import React from "react";

const BankDetails = () => {
  const { getBankDetails, updateBankDetails } = useRefferer();

  const [hasBankDetails, setHasBankDetails] = React.useState(false);
  const [bankName, setBankName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [accountHolder, setAccountHolder] = React.useState("");
  const [bankBranch, setBankBranch] = React.useState("");

  React.useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const data = await getBankDetails();

        if (data) {
          setHasBankDetails(true);
          setBankName(data.bankName || "");
          setAccountNumber(data.accountNumber || "");
          setAccountHolder(data.accountHolder || "");
          setBankBranch(data.bankBranch || "");
        }
      } catch (error) {
        console.error("Error fetching bank details:", error);
      }
    };

    fetchBankDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-3">
      <div className="card mx-auto" style={{ maxWidth: "600px" }}>
        <div className="card-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              updateBankDetails(bankName, accountNumber, bankBranch, accountHolder);
            }}
          >
            <div className="mb-3">
              <label htmlFor="bankName" className="form-label">
                Bank Name
              </label>
              <input
                onChange={(e) => setBankName(e.target.value)}
                type="text"
                className="form-control"
                id="bankName"
                placeholder="Enter your bank name"
                value={bankName}
                disabled={hasBankDetails}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="accountNumber" className="form-label">
                Account Number
              </label>
              <input
                onChange={(e) => setAccountNumber(e.target.value)}
                type="text"
                className="form-control"
                id="accountNumber"
                placeholder="Enter your account number"
                value={accountNumber}
                disabled={hasBankDetails}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="bankBranch" className="form-label">
                Bank Branch
              </label>
              <input
                onChange={(e) => setBankBranch(e.target.value)}
                type="text"
                className="form-control"
                id="bankBranch"
                placeholder="Enter the bank branch"
                value={bankBranch}
                disabled={hasBankDetails}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="accountHolder" className="form-label">
                Account Holder Name
              </label>
              <input
                onChange={(e) => setAccountHolder(e.target.value)}
                type="text"
                className="form-control"
                id="accountHolder"
                placeholder="Enter account holder's name"
                value={accountHolder}
                disabled={hasBankDetails}
              />
            </div>

            <button type="submit" className="btn btn-success w-100" disabled={hasBankDetails}>
              Submit
            </button>
          </form>

          <div className="alert alert-info mt-4">
            After applying for a withdrawal request and reviewing by us, you will get the amount
            deposited to your bank account within 2-7 working days.
          </div>

          <div className="alert alert-warning mt-4">
            <strong>Important:</strong> Bank details cannot be edited by you after submitting. If
            you need to edit them, contact us at{" "}
            <a href="mailto:support@whatsflow.xyz">support@whatsflow.xyz</a>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
