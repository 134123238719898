import React from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";

import Spacer from "components/Spacer";
import userStore from "store/userStore";
import { useHttp } from "hooks/useHttp";

const ScanQr = () => {
  const { protectedHttp } = useHttp();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const { whatsappLoggedIn } = userStore();
  const [qrCode, setQrCode] = React.useState("");

  const getQrCode = async () => {
    setLoading(true);
    try {
      const { data } = await protectedHttp.get("/client/get-qr");
      setQrCode(data.qr);
    } catch (error) {
      console.error("Get QR code error:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (whatsappLoggedIn) {
      navigate("/client/dashboard");
    } else {
      getQrCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatsappLoggedIn]);

  return (
    <>
      <Spacer height={50} />
      <div className="d-flex justify-content-center align-items-center">
        <div className="card col-sm col-md-5">
          <div className="card-body d-flex flex-column justify-content-center align-items-center">
            <h5 className="card-title">Scan the below QR code</h5>
            <p className="card-text">
              Open WhatsApp on your phone and scan the QR code to get started.
            </p>
            <div
              className="card d-flex justify-content-center align-items-center"
              style={styles.container}
            >
              {loading ? (
                <>
                  <div className="spinner-border" role="status"></div>
                  <Spacer height={40} />
                  <small className="text-muted text-center">Please wait...</small>

                  <small className="text-muted text-center">
                    This may take up to five minutes, sometimes.
                  </small>
                </>
              ) : (
                <QRCode value={qrCode} size={320} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScanQr;

const styles = {
  container: {
    width: "20rem",
    height: "20rem",
  },
};
