import React from "react";
import { Routes, Route } from "react-router-dom";

import ClientTopbar from "components/ClientTopbar";
import ScanQr from "./ScanQr";
import Dashboard from "./ClientDashboard";
import Templates from "./template/Templates";
import TopupHistory from "./TopupHistory";

const Panel = () => {
  return (
    <div>
      <ClientTopbar />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/templates/*" element={<Templates />} />
        <Route path="/topup-history" element={<TopupHistory />} />
        <Route path="/scan-qr" element={<ScanQr />} />
      </Routes>
    </div>
  );
};

export default Panel;
