import React from "react";
import { useDebounce } from "use-debounce";
import { MdDelete, MdEdit, MdRemoveRedEye } from "react-icons/md";
import { format, parse } from "date-fns";

import { useAdmin } from "hooks/useAdmin";

const Referrers = () => {
  const { getReferrers, getUser, createReferrer, updateReferrer, deleteUser } = useAdmin();

  const [users, setUsers] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [query, setQuery] = React.useState("");

  const [debouncedQuery] = useDebounce(query, 500);

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  // states for modal
  const [actionType, setActionType] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [totalEarnings, setTotalEarnings] = React.useState("");
  const [totalWithdrawals, setTotalWithdrawals] = React.useState("");
  const [availableAmountForWithdraw, setAvailableAmountForWithdraw] = React.useState("");

  const handleGetReferrers = async (debouncedQuery) => {
    const {
      users,
      page: currentPage,
      totalPages,
    } = await getReferrers(page, debouncedQuery ? debouncedQuery : query);
    setUsers(users);
    setPage(currentPage);
    setTotalPages(totalPages);
  };

  const handleGetReferrer = async (id) => {
    const {
      username,
      email,
      contactNumber,
      totalEarnings,
      totalWithdrawals,
      availableAmountForWithdraw,
    } = await getUser(id);

    setUserId(id);
    setUsername(username);
    setEmail(email);
    setContactNumber(contactNumber);
    setTotalPages(totalPages);

    setTotalEarnings(totalEarnings);
    setTotalWithdrawals(totalWithdrawals);
    setAvailableAmountForWithdraw(availableAmountForWithdraw);
  };

  React.useEffect(() => {
    handleGetReferrers(debouncedQuery !== "" ? debouncedQuery : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debouncedQuery]);

  const clearFields = () => {
    setUserId("");
    setUsername("");
    setEmail("");
    setPassword("");
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                {actionType === "view"
                  ? "View Referrer"
                  : actionType === "update"
                  ? "Edit Referrer"
                  : "Create Referrer"}
              </h1>
              <button
                onClick={clearFields}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                    disabled={actionType === "view"}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                    disabled={actionType === "view"}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <input
                    className="form-control"
                    id="contactNumber"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    placeholder="Contact Number (07xxxxxxxx)"
                    required
                    disabled={actionType === "view"}
                  />
                </div>
                {actionType !== "view" && (
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                      disabled={actionType === "view"}
                      type="password"
                    />
                  </div>
                )}
                {actionType === "view" && (
                  <>
                    <div className="mb-3">
                      <label htmlFor="totalEarnings" className="form-label">
                        Total Earnings
                      </label>
                      <input
                        className="form-control"
                        id="totalEarnings"
                        value={totalEarnings}
                        onChange={(e) => setTotalEarnings(e.target.value)}
                        placeholder="Total Earnings"
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="totalWithdrawals" className="form-label">
                        Total Withdrawals
                      </label>
                      <input
                        className="form-control"
                        id="totalWithdrawals"
                        value={totalWithdrawals}
                        onChange={(e) => setTotalWithdrawals(e.target.value)}
                        placeholder="Total Withdrawals"
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="availableAmountForWithdraw" className="form-label">
                        Available Amount For Withdraw
                      </label>
                      <input
                        className="form-control"
                        id="availableAmountForWithdraw"
                        value={availableAmountForWithdraw}
                        onChange={(e) => setAvailableAmountForWithdraw(e.target.value)}
                        placeholder="Available Amount For Withdraw"
                        disabled
                      />
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={clearFields}
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {actionType !== "view" && (
                <button
                  onClick={() =>
                    actionType === "create"
                      ? createReferrer(username, email, password, contactNumber)
                      : updateReferrer(userId, username, email, password, contactNumber)
                  }
                  type="button"
                  className="btn btn-success"
                >
                  {actionType === "update" ? "Update user" : "Create user"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center">
        <div className="card col-12 col-md-10 col-lg-10 mx-auto mt-4 table-responsive">
          <div className="row mb-4 px-3 py-2 align-items-center">
            <div className="col d-flex align-items-center gap-3">
              <div>
                <label htmlFor="searchUser" className="form-label">
                  Search Referrer:
                </label>
                <input
                  id="searchUser"
                  className="form-control"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="col-auto">
              <button
                onClick={() => {
                  setActionType("create");
                  clearFields();
                }}
                type="button"
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                Create Referrer
              </button>
            </div>
          </div>
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">Username</th>
                <th scope="col">Email</th>
                <th scope="col">Contact</th>
                <th scope="col">Joined at</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.contactNumber ? user.contactNumber : "None"}</td>
                  <td>
                    {format(
                      parse(user.joinedAt, "yyyy-MM-dd HH:mm", new Date()),
                      "yyyy MMMM dd, hh:mm a"
                    )}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={() => {
                          setActionType("view");
                          handleGetReferrer(user._id);
                        }}
                        className="rounded-circle bg-dark border-0 p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <MdRemoveRedEye color="white" />
                      </button>
                      &nbsp;
                      <button
                        onClick={() => {
                          setActionType("update");
                          handleGetReferrer(user._id);
                        }}
                        className="rounded-circle bg-warning border-0 p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <MdEdit />
                      </button>
                      &nbsp;
                      <button
                        onClick={() => {
                          clearFields();
                          deleteUser(user._id, handleGetReferrers);
                        }}
                        className="rounded-circle bg-danger border-0 p-2"
                      >
                        <MdDelete color="white" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="m-auto">
            <nav>
              <ul className="pagination justify-content-center">
                {startPage > 1 && (
                  <li onClick={() => setPage(startPage - 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <li
                    key={startPage + index}
                    onClick={() => setPage(startPage + index)}
                    className={`page-item ${startPage + index === page ? "active" : ""}`}
                  >
                    <a onClick={(e) => e.preventDefault()} className="page-link" href="/">
                      {startPage + index}
                    </a>
                  </li>
                ))}

                {endPage < totalPages && (
                  <li onClick={() => setPage(endPage + 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Next"
                    >
                      <span className="sr-only">Next</span>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referrers;
