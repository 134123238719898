import appStore from "store/appStore";
import useAlertEmiter from "./useAlertEmmiter";
import { useHttp } from "./useHttp";

export const useRefferer = () => {
  const { protectedHttp } = useHttp();
  const { emitError, emitSuccess } = useAlertEmiter();
  const { setLoading } = appStore();

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get("/refferer/dashboard");

      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getWithdrawalRecords = async (page, startDate, endDate) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(
        `/refferer/withdrawals?page=${page}&startDate=${startDate}&endDate=${endDate}`
      );
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const applyForAWithdrawal = async (amount) => {
    try {
      setLoading(true);
      await protectedHttp.post("/refferer/withdrawals", { amount });

      emitSuccess("Withdrawal request submitted successfully!");
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getBankDetails = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get("/refferer/bank-details");
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateBankDetails = async (bankName, accountNumber, bankBranch, accountHolder, cb) => {
    try {
      setLoading(true);
      await protectedHttp.post("/refferer/bank-details", {
        bankName,
        accountNumber,
        bankBranch,
        accountHolder,
      });
      window.location.reload();
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getSiteConfiguration = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(`/utils/configuration`);
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const getEarningHistory = async (page, startDate, endDate) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.get(
        `/refferer/earnings?page=${page}&startDate=${startDate}&endDate=${endDate}`
      );
      return data;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getDashboardData,
    getWithdrawalRecords,
    applyForAWithdrawal,
    getBankDetails,
    updateBankDetails,
    getSiteConfiguration,
    getEarningHistory,
  };
};
