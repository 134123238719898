import React from "react";
import NavBar from "./NavBar";

import { IoBarChartSharp } from "react-icons/io5";
import { FaClipboardList, FaMoneyBillWave, FaUsers } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import useAuthentication from "hooks/useAuthentication";

const AdminTopbar = () => {
  const { getInstance } = useAuthentication();
  const pathName = useLocation().pathname;

  React.useEffect(() => {
    getInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavBar.Container>
      <NavBar.Item
        to={"/admin/dashboard"}
        icon={<IoBarChartSharp />}
        label="Dashboard"
        active={pathName.includes("/admin/dashboard")}
      />
      <NavBar.Item
        to={"/admin/payments"}
        icon={<FaMoneyBillWave />}
        label="Payments"
        active={pathName.includes("/admin/payments")}
      />
      <NavBar.Item
        to={"/admin/withdrawals"}
        icon={<FaMoneyBillWave />}
        label="Withdrawals"
        active={pathName.includes("/admin/withdrawals")}
      />
      <NavBar.Item
        to={"/admin/users"}
        icon={<FaUsers />}
        label="Users"
        active={pathName.includes("/admin/users")}
      />
      <NavBar.Item
        to={"/admin/referrers"}
        icon={<FaUsers />}
        label="Referrers"
        active={pathName.includes("/admin/referrers")}
      />
      <NavBar.Item
        to={"/admin/notice-board"}
        icon={<FaClipboardList />}
        label="Notice Board"
        active={pathName.includes("/admin/notice-board")}
      />
    </NavBar.Container>
  );
};

export default AdminTopbar;
