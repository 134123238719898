import React from "react";
import { format, parse } from "date-fns";
import { useRefferer } from "hooks/useRefferer";

const Withdrawals = () => {
  const { getWithdrawalRecords, applyForAWithdrawal } = useRefferer();

  const [withdrawals, setWithdrawals] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [totalWithdrawedAmount, setTotalWithdrawedAmount] = React.useState("0");
  const [availableAmountForWithdraw, setAvailableAmountForWithdraw] = React.useState("0");

  const [withdrawAmount, setWithdrawAmount] = React.useState("");
  const [amountError, setAmountError] = React.useState("");

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  const handleGetPayments = async () => {
    const {
      withdrawals,
      page: currentPage,
      totalPages,
      totalWithdrawedAmount,
      availableAmountForWithdraw,
    } = await getWithdrawalRecords(page, startDate, endDate);
    setWithdrawals(withdrawals);
    setPage(currentPage);
    setTotalPages(totalPages);
    setTotalWithdrawedAmount(totalWithdrawedAmount);
    setAvailableAmountForWithdraw(availableAmountForWithdraw);
  };

  React.useEffect(() => {
    handleGetPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, startDate, endDate]);

  const handleApplyWithdrawal = () => {
    const amount = parseFloat(withdrawAmount);
    const availableAmount = parseFloat(availableAmountForWithdraw);

    if (amount > availableAmount) {
      setAmountError("Entered amount exceeds the available amount for withdrawal.");
      return;
    } else if (amount < 500) {
      setAmountError("The minimum withdrawal amount is 500 LKR.");
      return;
    }

    setAmountError("");
    applyForAWithdrawal(amount);

    window.location.reload();
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Apply for a withdrawal
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="withdrawAmount" className="form-label">
                    Amount to Withdraw (Available: {availableAmountForWithdraw} LKR):
                  </label>
                  <input
                    type="number"
                    className={`form-control ${amountError ? "is-invalid" : ""}`}
                    id="withdrawAmount"
                    placeholder="Enter amount"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                    min="500"
                    required
                  />
                  {amountError && <div className="invalid-feedback">{amountError}</div>}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>

              <button type="button" className="btn btn-success" onClick={handleApplyWithdrawal}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex flex-column align-items-center">
        <div className="card col-12 col-md-10 col-lg-8 mx-auto mt-4 table-responsive">
          <div className="row mb-4 px-3 py-2 align-items-center">
            <div className="col d-flex align-items-center gap-3">
              <div>
                <label htmlFor="startDate" className="form-label">
                  Start Date:
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="endDate" className="form-label">
                  End Date:
                </label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-auto d-flex flex-column gap-3 align-items-end">
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                Apply for a withdrawal
              </button>
              <span>Withdrawn Amount: {totalWithdrawedAmount} LKR</span>
            </div>
          </div>
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.map((withdrawal) => (
                <tr key={withdrawal._id}>
                  <td>{withdrawal.amount} LKR</td>
                  <td>
                    {format(
                      parse(withdrawal.date, "yyyy-MM-dd HH:mm", new Date()),
                      "yyyy MMMM dd, hh:mm a"
                    )}
                  </td>
                  <td className={withdrawal.status === 0 ? "text-danger" : "text-success"}>
                    {withdrawal.status === 0 ? "Pending" : "Approved"}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="m-auto">
            <nav>
              <ul className="pagination justify-content-center">
                {startPage > 1 && (
                  <li onClick={() => setPage(startPage - 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                  <li
                    key={startPage + index}
                    onClick={() => setPage(startPage + index)}
                    className={`page-item ${startPage + index === page ? "active" : ""}`}
                  >
                    <a onClick={(e) => e.preventDefault()} className="page-link" href="/">
                      {startPage + index}
                    </a>
                  </li>
                ))}

                {endPage < totalPages && (
                  <li onClick={() => setPage(endPage + 1)} className="page-item">
                    <a
                      onClick={(e) => e.preventDefault()}
                      className="page-link"
                      href="/"
                      aria-label="Next"
                    >
                      <span className="sr-only">Next</span>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdrawals;
