import React from "react";
import { Routes, Route } from "react-router-dom";

import AllTemplates from "./AllTemplates";
import CreateTemplate from "./CreateTemplate";
import ViewTemplate from "./ViewTemplate";
import UpdateTemplate from "./UpdateTemplate";

const Templates = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<AllTemplates />} />
        <Route path="/create" element={<CreateTemplate />} />
        <Route path="/view/:templateId" element={<ViewTemplate />} />
        <Route path="/edit/:templateId" element={<UpdateTemplate />} />
      </Routes>
    </div>
  );
};

export default Templates;
