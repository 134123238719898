import appStore from "store/appStore";
import useAlertEmiter from "./useAlertEmmiter";
import { useHttp } from "./useHttp";

export const usePayment = () => {
  const { protectedHttp } = useHttp();
  const { emitError } = useAlertEmiter();
  const { setLoading } = appStore();

  const handlePayment = async (credits, lkrAmount) => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.post("/payment", {
        credits,
        lkrAmount,
      });

      const paymentUrl = data.paymentUrl;
      window.location.href = paymentUrl;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEnterprisePayment = async () => {
    try {
      setLoading(true);
      const { data } = await protectedHttp.post("/payment/enterprise");

      const paymentUrl = data.paymentUrl;
      window.location.href = paymentUrl;
    } catch (error) {
      emitError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    handlePayment,
    handleEnterprisePayment,
  };
};
