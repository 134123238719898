import React from "react";

const Loader = () => {
  return (
    <div
      className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 9999,
      }}
    >
      <div
        className="spinner-border text-success"
        style={{
          width: "3rem",
          height: "3rem",
        }}
      />
    </div>
  );
};

export default Loader;
