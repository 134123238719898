import React from "react";
import { MdEdit, MdDelete, MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import useClient from "hooks/useClient";

const AllTemplates = () => {
  const navigate = useNavigate();
  const { getTemplates, deleteTemplate } = useClient();

  const [templates, setTemplates] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  const handleGetTemplates = async () => {
    const { templates, page: currentPage, totalPages } = await getTemplates(page);
    setTemplates(templates);
    setPage(currentPage);
    setTotalPages(totalPages);
  };

  React.useEffect(() => {
    handleGetTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="container d-flex flex-column align-items-center">
      <div className="row mt-4 align-items-center justify-content-center">
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="card">
            <div className="card-header">Text Only Template</div>
            <div className="card-body">
              <p className="card-text">It supports adding variables along with the texts.</p>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`create?template=text-only`);
                }}
                href="/"
                className="btn btn-primary"
              >
                Create template
              </a>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="card">
            <div className="card-header">Text with Media Template</div>
            <div className="card-body">
              <p className="card-text">
                It supports adding variables along with the texts and media.
              </p>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`create?template=text-with-media`);
                }}
                href="/"
                className="btn btn-primary"
              >
                Create template
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-center">
          Looking for the documentation?
          <a
            className="link-success"
            target="_blank"
            href="https://whatsflow.xyz/docs.html"
            rel="noreferrer"
          >
            {" "}
            Click here!
          </a>
        </p>
      </div>
      <div className="card col-12 col-md-10 col-lg-8 mx-auto mt-4 table-responsive">
        <table className="table">
          <thead className="table-dark">
            <tr>
              <th scope="col">Template</th>
              <th scope="col">Type</th>
              <th scope="col">Code</th>
              <th scope="col">Options</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((template) => (
              <tr key={template._id}>
                <td>{template.name}</td>
                <td>{template.type}</td>
                <td>{template.templateCode}</td>
                <td>
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={() => navigate(`view/${template._id}`)}
                      className="rounded-circle bg-dark border-0 p-2"
                    >
                      <MdRemoveRedEye color="white" />
                    </button>
                    &nbsp;
                    <button
                      onClick={() => navigate(`edit/${template._id}`)}
                      className="rounded-circle bg-warning border-0 p-2"
                    >
                      <MdEdit />
                    </button>
                    &nbsp;
                    <button
                      onClick={() => deleteTemplate(template._id, handleGetTemplates)}
                      className="rounded-circle bg-danger border-0 p-2"
                    >
                      <MdDelete color="white" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="m-auto">
          <nav>
            <ul className="pagination justify-content-center">
              {startPage > 1 && (
                <li onClick={() => setPage(startPage - 1)} className="page-item">
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="page-link"
                    href="/"
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
              )}

              {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                <li
                  key={startPage + index}
                  onClick={() => setPage(startPage + index)}
                  className={`page-item ${startPage + index === page ? "active" : ""}`}
                >
                  <a onClick={(e) => e.preventDefault()} className="page-link" href="/">
                    {startPage + index}
                  </a>
                </li>
              ))}

              {endPage < totalPages && (
                <li onClick={() => setPage(endPage + 1)} className="page-item">
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="page-link"
                    href="/"
                    aria-label="Next"
                  >
                    <span className="sr-only">Next</span>
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AllTemplates;
